body {
  color: #bfffd1;
  letter-spacing: -.3px;
  background-color: #000;
  font-family: Arial, sans-serif;
}

h1 {
  letter-spacing: -1px;
  font-size: 42px;
}

h2 {
  color: #6e8f77;
  margin: 12px 0;
}

hr {
  border: none;
  border-top: .5px solid #bfffd1;
  width: 100%;
  margin: 20px auto;
}

.website-container {
  grid-template-columns: 2fr 3fr;
  height: 100vh;
  display: grid;
  overflow: hidden;
}

.logo {
  width: 20%;
}

.text-block-1 p {
  font-size: 20px;
}

.services-container {
  flex-flow: wrap;
  gap: 16px;
  display: flex;
}

.service {
  color: #bfffd1;
  background-color: #111613;
  justify-content: center;
  padding: 6px 8px;
  font-size: 18px;
  display: inline-flex;
}

.call-to-action {
  color: #bfffd1;
  cursor: pointer;
  background-color: #111613;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  transition: background-color .3s, color .3s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.call-to-action:hover {
  color: #f1faf3;
  background-color: #6e8f77;
}

.call-to-action p {
  text-align: center;
  width: 100%;
  margin: 0;
}

.call-to-action .main-text {
  transition: transform .25s;
  display: block;
}

.call-to-action .hover-text {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: transform .25s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.call-to-action:hover .main-text {
  transform: translateY(-200%);
}

.call-to-action:hover .hover-text {
  transform: translateY(0);
}

.exampleBtn {
  color: "white";
  cursor: "pointer";
  overflow: "hidden";
  display: "block";
  position: "relative";
}

.main-text {
  transition: transform .5s;
  display: block;
}

.hover-text {
  width: 100%;
  height: 100%;
  transition: transform .25s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(0%);
}

.exampleBtn:hover .main-text {
  transition: transform .25s;
  transform: translateY(-100%);
}

.exampleBtn:hover .hover-text {
  transition: transform .25s;
  transform: translateY(0%);
}

.left-homepage {
  height: 100vh;
  padding: 24px;
  overflow-y: auto;
}

.right-homepage {
  will-change: transform;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  max-height: 100vh;
  padding: 24px 24px 48px;
  overflow-y: auto;
}

.right-homepage::-webkit-scrollbar {
  display: none;
}

.column-1-img {
  flex-flow: wrap;
  gap: 16px;
  padding: 8px 0;
  display: flex;
}

.column-1-img img {
  width: 100%;
  min-width: 0;
  max-width: 100%;
  height: auto;
}

.column-1-img video {
  object-fit: contain;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  height: auto;
}

.column-2-img {
  flex-flow: wrap;
  gap: 16px;
  padding: 8px 0;
  display: flex;
}

.column-2-img .vimeo-container {
  flex: 1;
  min-width: 0;
  height: auto;
}

.column-2-img img {
  object-fit: contain;
  flex: 1;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  height: auto;
}

.column-2-img video {
  flex: 1;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  height: auto;
}

.column-3-img {
  flex-direction: row;
  flex: 1;
  gap: 16px;
  padding: 8px 0;
  display: flex;
}

.column-3-img img, .column-3-img video {
  width: 100%;
  min-width: 0;
  max-width: 100%;
  height: auto;
}

.vimeo-container {
  background: #000;
  width: 100%;
  height: auto;
  position: relative;
}

.vimeo-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.vimeo-container:before {
  content: "";
  padding-top: 62.67%;
  display: block;
}

@media screen and (width <= 768px) {
  .website-container {
    grid-template-columns: 1fr;
    height: auto;
    display: grid;
    overflow: visible;
  }

  .left-homepage {
    height: 100%;
  }

  .logo {
    width: 40%;
  }

  h1 {
    font-size: 32px;
  }
}

@media screen and (width >= 769px) and (width <= 1268px) {
  .website-container {
    grid-template-columns: 1fr;
    height: auto;
    display: grid;
    overflow: visible;
  }

  .logo {
    width: 40%;
  }

  h1 {
    font-size: 32px;
  }
}
/*# sourceMappingURL=index.40d9484d.css.map */
