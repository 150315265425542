body {
  background-color: black;
  color:#bfffd1;
  font-family: 'Arial', sans-serif;
  letter-spacing: -0.3px;
}

h1{
    font-size: 42px;
    letter-spacing: -1px;
}

h2 {
    color:#6e8f77;
    margin: 12px 0 12px 0;
}
hr {
    width: 100%;
    margin: 20px auto;
    border: none;
    border-top: .5px solid #bfffd1
}
.website-container {
    display: grid;
    grid-template-columns: 2fr 3fr;
    height: 100vh;
    overflow: hidden;
}
.logo {
    width:20%;
}

.text-block-1 p {
    font-size: 20px;
}
.services-container {
    display:flex;
    flex-wrap: wrap;
    flex-direction:row;
    gap: 16px;
}
.service {
    font-size: 18px;
    color: #bfffd1;
    background-color: #111613;
    padding: 6px 8px;
    justify-content: center;
    display: inline-flex
}
.call-to-action {
    width: 100%;
    color: #bfffd1;
    background-color: #111613;
    padding: 12px 0 12px 0;
    justify-content: center;
    display: inline-flex;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}
.call-to-action:hover {
    background-color: #6e8f77;
    color: #f1faf3;
}
.call-to-action p {
    margin: 0;
    width: 100%;
    text-align: center;
}

.call-to-action .main-text {
    display: block;
    transition: transform 250ms;
}

.call-to-action .hover-text {
    transition: transform 250ms;
    transform: translateY(100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.call-to-action:hover .main-text {
    transform: translateY(-200%);
}

.call-to-action:hover .hover-text {
    transform: translateY(0);
}

/* test hover effect */

.exampleBtn {
	color: 'white';
	cursor: 'pointer';
	overflow: 'hidden';
	display: 'block';
	position: 'relative';
}

.main-text {
	display: block;
	transition: transform 500ms;
}

.hover-text {
	transition: transform 250ms;
	transform: translateY(0%);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.exampleBtn:hover .main-text {
	transition: transform 250ms;
	transform: translateY(-100%);
}

.exampleBtn:hover .hover-text {
	transition: transform 250ms;
	transform: translateY(0%);
}



/* text hover effect END */
.left-homepage {
    padding: 24px;
    height: 100vh;
    overflow-y: auto;
}

.right-homepage {
    max-height: 100vh;
    height: 100%;
    overflow-y: auto;
    padding: 24px;
    padding-bottom: 48px;
    will-change: transform;
    -webkit-overflow-scrolling: touch;
}
.right-homepage::-webkit-scrollbar {
    display:none;   
}

.column-1-img {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    gap: 16px;
    padding: 8px  0 8px 0;
}
.column-1-img img {
    max-width: 100%;
    height: auto;
    /* object-fit:contain; */
    min-width: 0;
    width: 100%;
}
.column-1-img video {
    max-width: 100%;
    height: auto;
    object-fit:contain;
    min-width: 0;
    width: 100%;
}
.column-2-img {
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    gap: 16px;
    padding: 8px 0 8px 0;

}
.column-2-img .vimeo-container {
    flex: 1;
    min-width: 0;
    height: auto;
}
.column-2-img img {
    flex: 1;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    min-width: 0;
    width: 100%;
}
.column-2-img video {
    max-width: 100%;
    height: auto;
    /* object-fit:contain; */
    min-width: 0;
    width: 100%;
    flex:1;
}
.column-3-img {
    display:flex;
    flex-direction:row;
    gap: 16px;
    padding: 8px 0 8px 0;
    flex:1;
}
.column-3-img img {
    max-width: 100%;
    height: auto;
    /* object-fit:contain; */
    min-width: 0;
    width: 100%;
}
.column-3-img video {
    max-width: 100%;
    height: auto;
    /* object-fit:contain; */
    min-width: 0;
    width: 100%;
}

.vimeo-container {
    position: relative;
    width: 100%;
    height: auto;
    background: #000; /* Optional: adds a black background while loading */
}

.vimeo-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.vimeo-container::before {
    content: "";
    display: block;
    padding-top: 62.67%; /* Maintains aspect ratio 16:10 */
}

@media screen and (max-width: 768px) {
    .website-container {
        display: grid;
        grid-template-columns: 1fr;
        height: auto;
        overflow: visible;
    }
    .left-homepage {
        height: 100%;
    }
    .logo {
        width: 40%;
    }
    h1 {
        font-size: 32px;
    }
}
@media screen and (min-width: 769px) and (max-width: 1268px) {
    .website-container {
        display: grid;
        grid-template-columns:  1fr;
        height: auto;
        overflow: visible;
    }

   
    .logo {
     width: 40%;
    }

    h1 {
        font-size: 32px;
    }
}